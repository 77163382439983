/*
IMPORTS
*/

// Main images
import M1 from './main/M-1.jpg';
import M2 from './main/M-2.jpg';
import M3 from './main/M-3.jpg';
import M4 from './main/M-4.jpg';
import M5 from './main/M-5.jpg';
import M6 from './main/M-6.jpg';
import M7 from './main/M-7.jpg';
import M8 from './main/M-8.jpg';
import M9 from './main/M-9.jpg';
import M10 from './main/M-10.jpg';
import M11 from './main/M-11.jpg';
import M12 from './main/M-12.jpg';
import M13 from './main/M-13.jpg';
import M14 from './main/M-14.jpg';
import M15 from './main/M-15.jpg';
import M16 from './main/M-16.jpg';
import M17 from './main/M-17.jpg';
import M18 from './main/M-18.jpg';
import M19 from './main/M-19.jpg';
import M20 from './main/M-20.jpg';
import M21 from './main/M-21.jpg';
import M22 from './main/M-22.jpg';
import M23 from './main/M-23.jpg';
import M24 from './main/M-24.jpg';
import M25 from './main/M-25.jpg';
import M26 from './main/M-26.jpg';
import M27 from './main/M-27.jpg';
import M28 from './main/M-28.jpg';
import M29 from './main/M-29.jpg';
import M30 from './main/M-30.jpg';

// Closeup images
import C1 from './close/C-1.jpg';
import C2 from './close/C-2.jpg';
import C3 from './close/C-3.jpg';
import C4 from './close/C-4.jpg';
import C5 from './close/C-5.jpg';
import C6 from './close/C-6.jpg';
import C7 from './close/C-7.jpg';
import C8 from './close/C-8.jpg';
import C9 from './close/C-9.jpg';
import C10 from './close/C-10.jpg';
import C11 from './close/C-11.jpg';
import C12 from './close/C-12.jpg';
import C13 from './close/C-13.jpg';
import C14 from './close/C-14.jpg';
import C15 from './close/C-15.jpg';
import C16 from './close/C-16.jpg';
import C17 from './close/C-17.jpg';
import C18 from './close/C-18.jpg';
import C19 from './close/C-19.jpg';
import C20 from './close/C-20.jpg';
import C21 from './close/C-21.jpg';
import C22 from './close/C-22.jpg';
import C23 from './close/C-23.jpg';
import C24 from './close/C-24.jpg';
import C25 from './close/C-25.jpg';
import C26 from './close/C-26.jpg';
import C27 from './close/C-27.jpg';
import C28 from './close/C-28.jpg';
import C29 from './close/C-29.jpg';
import C30 from './close/C-30.jpg';

// Other images
import BIPInstall from './other/BIPInstall.png';
import BridgeCurrent from './other/BridgeCurrent.png';
import BridgeHouseOGSketch from './other/BridgeHouseOGSketch.png';
import BridgeWasteSketch from './other/BridgeWasteSketch.png';
import C4CBuilding from './other/C4CBuilding.png';
import C4CInGarden from './other/C4CInGarden.png';
import C4COnCampus from './other/C4COnCampus.png';
import EntryA from './other/entryA.png';
import EntryB from './other/entryB.png';
import EntryC from './other/entryC.png';
import EntryD from './other/entryD.png';
import EntryE from './other/entryE.png';
import Fratessa1 from './other/fratessa1.png';

/*
EXPORTS
*/

// Main images
export const mainImages = {
  'M-1': M1,
  'M-2': M2,
  'M-3': M3,
  'M-4': M4,
  'M-5': M5,
  'M-6': M6,
  'M-7': M7,
  'M-8': M8,
  'M-9': M9,
  'M-10': M10,
  'M-11': M11,
  'M-12': M12,
  'M-13': M13,
  'M-14': M14,
  'M-15': M15,
  'M-16': M16,
  'M-17': M17,
  'M-18': M18,
  'M-19': M19,
  'M-20': M20,
  'M-21': M21,
  'M-22': M22,
  'M-23': M23,
  'M-24': M24,
  'M-25': M25,
  'M-26': M26,
  'M-27': M27,
  'M-28': M28,
  'M-29': M29,
  'M-30': M30,
};

// Closeup images
export const closeUpImages = {
  'C-1': C1,
  'C-2': C2,
  'C-3': C3,
  'C-4': C4,
  'C-5': C5,
  'C-6': C6,
  'C-7': C7,
  'C-8': C8,
  'C-9': C9,
  'C-10': C10,
  'C-11': C11,
  'C-12': C12,
  'C-13': C13,
  'C-14': C14,
  'C-15': C15,
  'C-16': C16,
  'C-17': C17,
  'C-18': C18,
  'C-19': C19,
  'C-20': C20,
  'C-21': C21,
  'C-22': C22,
  'C-23': C23,
  'C-24': C24,
  'C-25': C25,
  'C-26': C26,
  'C-27': C27,
  'C-28': C28,
  'C-29': C29,
  'C-30': C30,
};

// Other images
export const otherImages = {
  BIPInstall,
  BridgeCurrent,
  BridgeHouseOGSketch,
  BridgeWasteSketch,
  C4CBuilding,
  C4CInGarden,
  C4COnCampus,
  EntryA,
  EntryB,
  EntryC,
  EntryD,
  EntryE,
  Fratessa1,
};
